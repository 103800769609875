import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './components/App';
import ImagePage from './components/ImagePage';

const rootElement = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/media" element={<ImagePage />} /> {/* Add the new route */}
        </Routes>
    </Router>
);