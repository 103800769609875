import React, { useState } from 'react';
import { file_api } from '../api/api';

const ImagePage = () => {
    const [mediaId, setMediaId] = useState('');
    const [mediaBytes, setMediaBytes] = useState('');
    const [mediaUrl, setMediaURL] = useState('');
    const [mediaType, setMediaType] = useState('');

    const handleInputChange = (e) => {
        setMediaId(e.target.value);
    };

    const fetchMedia = async () => {
        try {
            const response = await file_api.get(`/api/media`, { params: { mediaId } });
            const contentType = response.headers['content-type'];
            const [mediaType] = contentType.split(';');
            // mediaBytes is a Buffer object
            const mediaBytes = response.data;
            const mediaBlob = new Blob([mediaBytes], { type: mediaType });
            const mediaUrl = URL.createObjectURL(mediaBlob);
            setMediaBytes(response.data);
            setMediaURL(mediaUrl);
            setMediaType(mediaType);
        } catch (error) {
            console.error('Error fetching media:', error);
            setMediaBytes('');
            setMediaURL('');
            setMediaType('');
        }
    };

    const renderMedia = () => {
        if (mediaType.startsWith('image/')) {
            // You can also use `emded` tag to display images.
            return <img alt='Failed to fetch media.' src={mediaUrl} width="80%" height="80%" />;
        } else if (mediaType.startsWith('application/pdf')) {
            return <embed src={mediaUrl} type="application/pdf" width="80%" height="600px" />;
        } else {
            return <p>Unsupported media type: {mediaType}</p>;
        }
    };

    return (
        <div className="App">
            <h1>Media Page</h1>
            <input type="number" value={mediaId} onChange={handleInputChange} placeholder="Enter media ID" />
            <button onClick={fetchMedia}>Fetch Media</button>
            {mediaBytes && renderMedia()}
        </div>
    );
};

export default ImagePage;