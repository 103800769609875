import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = 'http://localhost:8080';

const api = axios.create({
  baseURL: baseURL,
  headers: {
      'Content-Type': 'application/json'
  },
});

const file_api = axios.create({
  baseURL: baseURL,
  headers: {
      'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
});

// api.interceptors.request.use(
//   config => {
//     const token = process.env.REACT_APP_AUTH_HEADER;
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

export { api, file_api };
